<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="pa-4">
        <h1 class="mb-5 text--xl font-weight-medium">{{ $t('navigation.general_settings') }}</h1>
        <template v-if="loader">
            <v-row>
                <v-col cols="12" md="6">
                    <v-skeleton-loader v-if="loader"
                                       class=""
                                       width="100%"
                                       height="400"
                                       type="card"
                    ></v-skeleton-loader>
                </v-col>
                <v-col cols="12" md="6">
                    <v-skeleton-loader v-if="loader"
                                       class=""
                                       width="100%"
                                       height="400"
                                       type="card"
                    ></v-skeleton-loader>
                </v-col>
            </v-row>
        </template>
        <template v-if="!loader">
            <v-row>
                <v-col cols="12" md="6"><p>{{ $t('navigation.general') }}</p>
                    <TextField v-for="(item, index) in generalSettingsTextFields" :key="index + item.id"
                               v-model="item.value"
                               :readonly="!canUpdate"
                               :rules=rules(item)
                               :name="`${item.setting_type}`"
                               @change="updateList(item.id)"
                               :label="$t(`labels.${item.setting_type}`)"></TextField>
                    <Autocomplete :label="$t('labels.items_per_page')"
                                  :items="itemsPerPageList"
                                  name="table_items_per_page"
                                  @change="updateList(itemsPerPage.id)"
                                  v-model="itemsPerPage.value"></Autocomplete>
                    <Checkbox v-for="(item, index) in generalSettingsCheckboxes" :key="index"
                              v-model="item.value"
                              :readonly="!canUpdate"
                              @change="updateList(item.id)"
                              class="mt-1"
                              false-value="0"
                              true-value="1"
                              :label="$t(`labels.${item.setting_type}`)"></Checkbox>
                    <v-tabs :dark="false"
                            :icons-and-text="false"
                            :grow="false">
                        <v-tab v-for="(tab, index) in headerVisibilityTabs()" :key="index">
                            <v-icon top class="mr-2">
                                {{ tab.icon }}
                            </v-icon>
                            {{ tab.title }}
                        </v-tab>
                        <v-tab-item v-for="(tab, index) in headerVisibilityTabs()" :key="index">
                            <v-col cols="12">
                                <Checkbox v-for="(item, index) in tab.values" :key="index"
                                          v-model="item.value"
                                          :readonly="!canUpdate"
                                          @change="updateList(item.id)"
                                          false-value="0"
                                          true-value="1"
                                          :label="$t(`labels.${item.setting_type}`)"></Checkbox>
                            </v-col>
                        </v-tab-item>
                    </v-tabs>
                </v-col>
                <v-col cols="12" md="6"><p>{{ $t('labels.identifiers') }}</p>
                    <TextField v-for="(item, index) in identifiers" :key="index"
                               v-model="item.value"
                               :readonly="!canUpdate"
                               @change="updateList(item.id)"
                               :name="`${item.setting_type}`"
                               :label="$t(`labels.${item.setting_type}`)"></TextField>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="6">
                    <p>{{ $t('navigation.reports') }}</p>
                    <TextField v-for="(item, index) in reportSettingsTextFields" :key="index + item.id"
                               v-model="item.value"
                               :readonly="!canUpdate"
                               :name="`${item.setting_type}`"
                               @change="updateList(item.id)"
                               :label="$t(`labels.${item.setting_type}`)"></TextField>
                </v-col>
            </v-row>
            <v-btn v-if="canUpdate"
                   color="secondary"
                   @click="save()"
                   :loading="loader"
                   :disabled="loader"
            >{{ $t('buttons.save') }}
            </v-btn>
        </template>
    </div>
</template>
<script>
import { mapFields } from 'vuex-map-fields';
import TextField from '@/components/widgets/forms/TextField';
import { ICONS } from '@/constants/icons';
import systemSettingsApi from '@/utils/api/settings';
import Checkbox from '@/components/widgets/forms/Checkbox';
import Autocomplete from '@/components/widgets/forms/Autocomplete';

export default {
    name: 'ConfigGlobal',
    components: { Autocomplete, TextField, Checkbox },

    data: () => ({
        loader: false,
        updatedIds: [],
        ICONS,
        generalSettingsCheckboxesConfig: ['INC_COMMENTS', 'GPS_SHOW_STATUS', 'HIDE_TIMESTAMP'],
        generalSettingsInputConfig: ['NUMBER_PER_PAGE']
    }),
    computed: {
        ...mapFields('systemSettings', [
            'settings'
        ]),
        generalSettingsTextFields () {
            return this.sortInputs(this.settings.filter(e => e.setting_type === 'ADMIN_EMAIL' ||
                e.setting_type === 'APP_GPS_DISTANCE'));
        },
        generalSettingsCheckboxes () {
            return this.sortInputs(this.settings.filter(e => this.generalSettingsCheckboxesConfig.includes(e.setting_type)));
        },
        itemsPerPage: {
            get () {
                const val = this.settings.find(e => e.setting_type === 'NUMBER_PER_PAGE');
                if (val) {
                    val.value = parseInt(val.value);
                    return val;
                }
                return val;
            },
            set (val) {
                this.settings.find(e => e.setting_type === 'NUMBER_PER_PAGE').value = val;
            }
        },
        inspectionsSettings () {
            return this.sortInputs(this.settings.filter(e => e.group === 'General_settings_inspection'));
        },
        incidentsSettings () {
            return this.sortInputs(this.settings.filter(e => e.group === 'General_settings_incident'));
        },
        reportSettingsTextFields () {
            return this.sortInputs(this.settings.filter(e => e.group === 'Reports'));
        },
        identifiers () {
            return this.sortInputs(this.settings.filter(e => e.group === 'Identifier'));
        },
        itemsPerPageList () {
            return [
                { text: '20', value: 20 },
                { text: '50', value: 50 },
                { text: '100', value: 100 }
            ];
        },
        canUpdate () {
            return this.$can(this.$abilityActions.UPDATE, this.$abilitySubjects.SYSTEM_SETTINGS);
        }
    },
    methods: {
        sortInputs (arr) {
            return arr.sort((a, b) => {
                return a.setting_type > b.setting_type;
            });
        },
        rules (item) {
            if (item && item.setting_type === 'mailAddress') {
                return { max: 40, email: true };
            } else return {};
        },
        updateList (id) {
            if (this.updatedIds.indexOf(id) === -1) {
                this.updatedIds.push(id);
            }
        },
        updateModel (item) {
            if (item) {
                let val = item.value;
                if (item.value === false) val = '0';
                if (item.value === true) val = '1';
                return {
                    id: item.id,
                    type: 'system_settings',
                    attributes: {
                        setting_type: item.setting_type,
                        value: val
                    }
                };
            }
        },
        async save () {
            const dataToUpdate = this.settings.filter(el => this.updatedIds.includes(el.id)).map(item => this.updateModel(item));
            if (dataToUpdate.length > 0 && this.canUpdate) {
                return await systemSettingsApi.updateSettings(dataToUpdate).finally(() => {
                    this.fetch();
                    this.updatedIds = [];
                });
            }
        },
        fetch () {
            this.loader = true;
            this.$store.dispatch('systemSettings/getAllSettings')
                .finally(() => {
                    this.loader = false;
                });
        },
        headerVisibilityTabs () {
            return [
                {
                    icon: 'mdi-view-dashboard',
                    title: this.$t('navigation.inspections'),
                    values: this.inspectionsSettings
                },
                {
                    icon: 'mdi-view-list',
                    title: this.$t('navigation.incidents'),
                    values: this.incidentsSettings
                }
            ];
        }
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            if (vm.$can(vm.$abilityActions.READ, vm.$abilitySubjects.SYSTEM_SETTINGS)) {
                vm.fetch();
            } else {
                vm.$router.push({ name: 'home' });
            }
        });
    }
};
</script>
